<template>
  <section class="profile">
    <div class="avatar-container">
      <Avatar
        :fullname="profile.displayName || $route.params.userId + '@bye.email' || handle"
        :email="msg && msg.parsed.from.address"
        :fromTld="msg && msg.fromTld"
        :size="96"
        style="float: left; margin-right: 10px"
      />

      <h1 class="name is-1 title">{{ profile.displayName }}</h1>
    </div>

    <!-- 
   Using bye since... format with timeago 
       -->
    <div class="using-bye-since">
      <span>Using Bye since {{ timeAgo(profile.createdAt) }}</span>
    </div>

    <div class="invite-button">
      <router-link :to="'/invite/' + profile.handle + '?email=' + profile.email" class="button is-primary">
        <button class="button is-primary">Add to an inbox</button>
      </router-link>
    </div>

    <div class="info-container">
      <div class="info-row">
        <span class="label">Email:</span>
        <span class="value">{{ profile.displayName }}</span>
      </div>
      <div class="info-row">
        <span class="label">Phone:</span>
        <span class="value">{{ profile.phone }}</span>
      </div>
      <div class="info-row">
        <span class="label">Address:</span>
        <span class="value">{{ profile.address }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import Avatar from "@/components/avatar.vue";

import { elipsis, timeAgo } from "../common/utils";

export default {
  name: "Profile",
  components: {
    Avatar,
  },
  data() {
    return {
      profile: {},
    };
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    timeAgo,
    async fetchProfile() {
      try {
        //const response = await fetch("https://example.com/profile");
        // const data = await response.json();

        this.profile = await window.API.userProfile(this.$route.params.handle);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-size: 2rem;
  margin-top: 1rem;
}

.info-container {
  margin-top: 2rem;
}

.info-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.label {
  font-weight: bold;
  margin-right: 1rem;
}

.value {
  font-weight: normal;
}
</style>
